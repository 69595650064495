import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment-timezone'
import EventTile from '../../global/tiles/EventTile'
import { LANDSCAPE_TILE } from '../../../constants/content-tile-types'

class EventsListModule extends Component {
  static propTypes = {
    events: PropTypes.array,
    headline: PropTypes.string,
    moduleClass: PropTypes.string,
    description: PropTypes.string,
    sidebarModule: PropTypes.element,
    categoryFilters: PropTypes.array
  }

  getDate = (start_time, timezone) => {
    const start = moment.tz(start_time, timezone)
    return (
      <span className="no-bg">
        {start.format('dddd')}
        {','}
        <br />
        {start.format('MMM')} {start.format('D')}
      </span>
    )
  }

  renderEvents = () => {
    const { events } = this.props
    const eventsArr = events.map((event, i) => {
      const eventDate = moment.tz(event.start_time, event.timezone);
      const prevDate = i === 0 ? moment.tz(this.props.prevDate, event.timezone) : moment.tz(events[i - 1]?.start_time, event.timezone);
      const showStamp = (i === 0 && !this.props.prevDate) || !eventDate.isSame(prevDate, "day")
      return (
        <div
          className={`${!showStamp ? 'no-stamp' : 'stamp'} row row--events-list-item`}
          key={String(event.id + i)}
        >
          <div
            className={`${
              !showStamp ? 'no-stamp' : 'stamp'
            } col s12 m4 l3 xl3 heading heading--secondary`}
          >
            {showStamp && (
              <h2 className="heading__headline">
                {this.getDate(event.start_time, event.timezone)}
              </h2>
            )}
          </div>
          <EventTile
            type={LANDSCAPE_TILE}
            viewType={'list'}
            column={`${!showStamp ? 'no-stamp' : 'stamp'} col s12 m8 l9 xl8 landscape-tile`}
            categoryFilters={this.props.categoryFilters}
            {...event}
          />
        </div>
      )
    })

    return eventsArr
  }

  render() {
    return this.renderEvents()
  }
}

export default EventsListModule
