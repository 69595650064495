import { normalize } from "normalizr";
import _findKey from "lodash/findKey";
import { CATEGORY_SCHEMA, REGION_SCHEMA } from "@constants/config";

export function getAllCategoryFilters(filters) {
  if (!filters || filters.categories.length <= 0) return [];

  const normalizedCategories = normalize(filters.categories, [CATEGORY_SCHEMA]);
  const subCategories = normalizedCategories.entities.subcategories;

  return Object.keys(subCategories).map(function (key) {
    return subCategories[key];
  });
}

export function compileLocationFilters(filters) {
  const normalizedLocations = normalize(filters.location, [REGION_SCHEMA]);
  const currentRegion = _findKey(normalizedLocations.entities.regions, [
    "selected",
    true,
  ]);
  if (!currentRegion) return [];

  const locationFilters = normalizedLocations.entities.regions[
    currentRegion
  ].neighborhoods.filter((hoodKey) => {
    const hood = normalizedLocations.entities.neighborhoods[hoodKey];
    if (hood.selected) return true;
    return false;
  });

  locationFilters.unshift(currentRegion);
  return locationFilters;
}

export function compileCategoryFilters(filters) {
  const normalizedCategories = normalize(filters.categories, [CATEGORY_SCHEMA]);
  const subCategories = Object.keys(
    normalizedCategories.entities.subcategories
  );
  return subCategories.filter((subCategory) => {
    const subCat = normalizedCategories.entities.subcategories[subCategory];
    if (subCat.selected) return true;
    return false;
  });
}
