import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import { navigate } from "gatsby";
import EventsListModule from "@components/modules/common/events-list-module";
import { configureHeader } from "@actions/ui";
import { compareGrade } from "@utils/misc";
import { getAllCategoryFilters } from "@utils/filter-utils";
import { searchPaginate, clearSearch } from "@actions/search";
import { getFilters, currentLocation } from "@selectors/filters";
import { EVENT_STATE_PUBLISHED, GA } from "@constants/config";
import { isWindowDefined } from "../assets/third-party/blast";
import SadFace from "@assets/image/sad-face.png";

class EventsSearch extends Component {
  constructor(props) {
    super(props);
    this.canPaginate = true;
    this.query = undefined;
  }

  componentDidMount = () => {
    if (isWindowDefined) {
      const searchParams = new URLSearchParams(window.location.search);
      const query = searchParams.get("query");
      if (query) {
        this.query = query;
        window && window.scrollTo(0, 0);
        window && window.fbq && window.fbq("track", "PageView");
        window && window.ga && window.ga("send", "pageview", GA.PAGES.search);

        this.props.configureHeader({
          headerVisibilityLocked: true,
          headerTheme: "white",
        });
      } else {
        navigate("/events");
      }
    }
  };

  componentDidUpdate = () => {
    if (isWindowDefined) {
      const searchParams = new URLSearchParams(window.location.search);
      const query = searchParams.get("query");
      if (this.query !== query) {
        this.canPaginate = true;
        this.props.clearSearch();
      }
    }
  };

  componentWillUnmount = () => {
    this.props.clearSearch();
  };

  getFilteredContent = (content) => {
    const c = content;

    let eventDate = undefined;

    let dailyEventBatch;
    const eventsArray = [];
    c.filter((event) => {
      // EXCLUDE ALL EVENTS WHO HAVE NO TAG ASSIGNED (INVALID)
      if (!event.tags) return false;
      if (event.event_state !== EVENT_STATE_PUBLISHED) return false;

      if (eventDate === undefined) {
        eventDate = moment.tz(event.start_time, event.timezone);
        event.stamp = true;
        dailyEventBatch = [];
        dailyEventBatch.push(event);
      } else {
        if (!eventDate.isSame(event.start_time, "day")) {
          this.mergeBatchedEvents(eventsArray, dailyEventBatch);

          eventDate = moment.tz(event.start_time, event.timezone);
          event.stamp = true;

          dailyEventBatch = [];
          dailyEventBatch.push(event);
        } else {
          event.stamp = false;
          dailyEventBatch.push(event);
        }
      }

      return true;
    });

    if (dailyEventBatch && dailyEventBatch.length > 0) {
      this.mergeBatchedEvents(eventsArray, dailyEventBatch);
    }

    return _.flatten(eventsArray);
  };

  mergeBatchedEvents = (source, batch) => {
    batch.sort(compareGrade).forEach((item, index) => {
      if (index === 0) item.stamp = true;
      else item.stamp = false;
    });
    source.push(batch);
  };

  renderEventListModules = (filteredContent) => {
    const eventGrids = [];
    for (let i = 0; i <= this.props.currentPage; i++) {
      eventGrids.push(
        <EventsListModule
          key={"eventgrid-" + i}
          events={filteredContent ? filteredContent : []}
          categoryFilters={getAllCategoryFilters(this.props.filters)}
        />
      );
    }
    return eventGrids;
  };

  render() {
    const { events } = this.props;
    const searchParams = new URLSearchParams(this.props.location.search);
    const query = searchParams.get("query");
    const prettyQuery = query ? query.replace(/,/g, " ") : "N/A";
    const filtered = events.filter((event) => {
      return (
        event?.tags?.toLowerCase().includes(prettyQuery?.toLowerCase()) ||
        event?.description
          ?.toLowerCase()
          .includes(prettyQuery?.toLowerCase()) ||
        event?.venue?.name
          ?.toLowerCase()
          .includes(prettyQuery?.toLowerCase()) ||
        event?.name.toLowerCase().includes(prettyQuery?.toLowerCase())
      );
    }).sort((a, b) => a.start_time.localeCompare(b.start_time));
    const filteredContent = this.getFilteredContent(filtered);

    return (
      <div className="events-container">
        <div className="row">
          <div className="search-events-header">
            <div className="search-events-header__headline">
              {this.props.searchInProgress ? (
                <span
                  className={`search-events-header__search-text ${this.props.searchInProgress
                    ? "search-events-header__search-text--inProgress"
                    : ""
                    }`}
                >
                  {`Searching for “${prettyQuery}”`}
                </span>
              ) : (
                <span
                  className={`search-events-header__search-text ${this.props.searchInProgress
                    ? "search-events-header__search-text--inProgress"
                    : ""
                    }`}
                >
                  {`Showing ${filteredContent ? filteredContent.length : 0
                    } results for “${prettyQuery}”`}
                </span>
              )}
            </div>
            {this.props.searchInProgress && (
              <div className="search-events-header__loading-bar">
                <div className="orange-bar"></div>
                <div className="grey-bar"></div>
              </div>
            )}
          </div>
        </div>
        {filteredContent && filteredContent.length <= 0 ? (
          <div className="row">
            <div className="col s12">
              <div className="no-events__content">
                <div className="no-events__graphic">
                  <img src={SadFace} alt="" />
                </div>
                <div className="no-events__headline">{"No Results?"}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container container__events">
            {this.renderEventListModules(filteredContent)}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    currentPage: state.search.currentPage,
    searchInProgress: state.search.hasStarted,
    filters: getFilters(state),
    currentRegion: currentLocation(state),
    browser: state.browser,
    platform: state.platform,
    events: state.events.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    paginate: () => {
      dispatch(searchPaginate());
    },
    clearSearch: () => {
      dispatch(clearSearch());
    },
  };
};

const SearchRedux = connect(mapStateToProps, mapDispatchToProps)(EventsSearch);

export default SearchRedux;

export const Head = () => <title>Search Page</title>;
