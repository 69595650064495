export function compareGrade(a, b) {
  if (a.event_grade > b.event_grade) {
    return -1;
  }

  if (a.event_grade < b.event_grade) {
    return 1;
  }

  return 0;
}
