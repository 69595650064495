import { CLEAR_SEARCH, SEARCH_PAGINATE } from "@constants/action-types";

export function searchPaginate() {
  return {
    type: SEARCH_PAGINATE,
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}
